/*Media*/
/*Colors*/
/*Fonts*/
@font-face {
  font-family: 'Druk';
  src: url('../fonts/Druk-Medium.woff2') format('woff2'), url('../fonts/Druk-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'heliosext';
  src: url('../fonts/heliosext.woff2') format('woff2'), url('../fonts/heliosext.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'heliosext';
  src: url('../fonts/heliosext-bold.woff2') format('woff2'), url('../fonts/heliosext-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
/*General*/
html {
  font-size: 62.5%;
}
html,
body {
  height: 100%;
}
* {
  outline: none;
}
body,
ol,
ul,
p {
  padding: 0;
  margin: 0;
}
body {
  font-family: 'heliosext', sans-serif;
  min-width: 320px;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: subpixel-antialiased;
}
.content {
  flex: 1 0 auto;
}
ul {
  list-style: none;
}
input,
button,
textarea {
  font-family: 'gothampro', sans-serif;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
button:not(:disabled) {
  cursor: pointer;
}
.container {
  max-width: 144rem;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
@media print, (min-width: 1024px) {
  .container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
.btn {
  display: inline-block;
  font-family: 'Druk', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  padding: 1.1rem;
}
.btn--red-border {
  color: #FE6F61;
  background-color: transparent;
  border: 2px solid #FE6F61;
  border-radius: 8px;
}
.btn--white-border {
  color: #fff;
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 8px;
}
.btn--green-border {
  color: #005944;
  background-color: #ffffff;
  border: 2px solid #005944;
  border-radius: 8px;
}
.btn--green {
  color: #ffffff;
  background-color: #005944;
  border: none;
  border-radius: 4px;
}
@media print, (min-width: 768px) {
  .btn--green {
    border-radius: 14px;
  }
}
.btn:disabled {
  opacity: 0.5;
}
.btn:not(:disabled) {
  cursor: pointer;
}
.search-form {
  position: relative;
  display: inline-block;
}
.search-form:after {
  content: url('../img/icons/search-white.svg');
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
}
.search-form__input {
  width: 26rem;
  font-family: 'heliosext', sans-serif;
  font-size: 1.6rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 17px;
  border: none;
  box-sizing: border-box;
  padding: 0.7rem 3rem 0.7rem 1rem;
}
.search-form__input::placeholder {
  color: #4C8B7C;
  opacity: 1;
}
.search-form__input:focus {
  background-color: #fff;
}
.lang-panel {
  display: flex;
  align-items: center;
  font-family: 'Druk', sans-serif;
}
.lang-panel__item {
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff;
  opacity: 0.2;
  text-transform: uppercase;
  text-decoration: none;
}
.lang-panel__item + .lang-panel__item {
  margin-left: 1.5rem;
}
.lang-panel__item:hover,
.lang-panel__item--active {
  opacity: 1;
}
.header-text {
  font-family: 'Druk', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.4em;
  color: #1a1a1a;
}
@media print, (min-width: 768px) {
  .header-text {
    font-size: 2.5rem;
  }
}
@media print, (min-width: 1280px) {
  .header-text {
    font-size: 3.5rem;
  }
}
.header-text--history {
  font-size: 2.5rem;
  max-width: 70rem;
  margin-bottom: 4rem;
}
.header-text--mission {
  line-height: 1.7em;
  text-transform: uppercase;
  margin-bottom: 6.6rem;
}
@media print, (min-width: 1024px) {
  .header-text--mission {
    font-size: 3rem;
  }
}
.header-text--career {
  color: #FE6F61;
  margin-bottom: 5rem;
}
.arrow-link {
  display: inline-flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
  color: #FE6F61;
  text-decoration: none;
}
.arrow-link span {
  border-bottom: 1px solid;
}
.arrow-link:after {
  content: '';
  width: 5.2rem;
  height: 5.2rem;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M51.0021 26C51.0021 39.7959 39.3201 51 25.6832 51C12.0621 51 1 39.812 1 26C1 12.2041 12.0462 1 25.6832 1C39.3201 1 51.0021 12.2041 51.0021 26Z' stroke='%23FE6F61' stroke-miterlimit='10'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.8821 13.6174C24.7287 13.4662 24.7287 13.2394 24.8821 13.1134C25.0354 12.9622 25.2654 12.9622 25.3931 13.1134L38.885 26.4187C39.0383 26.5699 39.0383 26.7967 38.885 26.9227L25.342 40.8832C25.2143 41.0344 24.9587 41.0344 24.831 40.9084C24.7032 40.7824 24.6776 40.5304 24.8054 40.4044L35.1543 29.7199C35.4609 29.0647 35.5631 28.5103 35.3076 28.0819C35.0521 27.6535 34.6177 27.3259 34.0555 27.0487H13.3577C13.1533 27.0487 13 26.8975 13 26.6959C13 26.4943 13.1533 26.3431 13.3577 26.3431H34.541C34.9754 26.1415 35.2821 25.8643 35.4609 25.5367C35.6909 25.0832 35.512 24.428 35.0521 23.672L24.8821 13.6174Z' fill='%23FE6F61'/%3E%3C/svg%3E%0A");
  margin-left: 2rem;
}
.slider-arrow {
  border: none;
  background-color: transparent;
}
.slider-arrow--disabled {
  opacity: 0.5;
  cursor: default;
}
.check {
  cursor: pointer;
}
.check__input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
.check__input:checked + .check__label:before {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='22' height='22' rx='4' fill='white' stroke='%23CECECE' stroke-width='2'/%3E%3Cpath d='M6 11.45L10.0345 15.5L19 6.5' stroke='%232A2A2A' stroke-width='2'/%3E%3C/svg%3E%0A");
}
.check__label {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.46em;
  color: #414141;
  padding-left: 3.2rem;
}
.check__label:before {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='22' height='22' rx='4' fill='white' stroke='%23CECECE' stroke-width='2'/%3E%3C/svg%3E%0A");
}
.input-line__input {
  font-family: 'heliosext', sans-serif;
  font-size: 1.4rem;
  color: #1A1A1A;
  width: 100%;
  padding: 1.6rem;
  box-sizing: border-box;
  border: 1px solid #F0F0F0;
  background-color: #F0F0F0;
  border-radius: 1rem;
}
.input-line__input::placeholder {
  color: #909090;
}
.input-line__input--textarea {
  resize: none;
}
.input-line__input.has-error {
  border-color: #c00;
}
.select {
  position: relative;
}
.select:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1.5rem;
  width: 0.8rem;
  height: 0.8rem;
  border-right: 2px solid #1A1A1A;
  border-bottom: 2px solid #1A1A1A;
  transform: rotate(45deg) translate(-50%, -50%);
}
.select select {
  width: 100%;
  font-family: 'heliosext', sans-serif;
  font-size: 1.4rem;
  color: #1A1A1A;
  padding: 13px 30px 13px 15px;
  border: 1px solid #F0F0F0;
  background-color: #F0F0F0;
  border-radius: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.select select:invalid:not(:focus) {
  color: #909090;
}
.select select.has-error {
  border-color: #c00;
}
.menu-list {
  font-family: 'Druk', sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  text-transform: uppercase;
}
.menu-list__item {
  margin-bottom: 1.5rem;
}
.menu-list__link {
  color: #1a1a1a;
  text-decoration: none;
}
.menu-list__link--active {
  color: #FE6F61;
}
.header-green {
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #005944;
  margin-bottom: 0.3em;
}
.perc-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  font-family: 'Druk', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #005944;
  text-transform: uppercase;
}
.perc-list__item {
  width: 50%;
}
@media (max-width: 767px) {
  .perc-list__item {
    padding-right: 1.5rem;
    box-sizing: border-box;
    margin-bottom: 2rem;
  }
}
@media print, (min-width: 1024px) {
  .perc-list__item {
    width: 15rem;
  }
  .perc-list__item:not(:last-child) {
    margin-right: 2rem;
  }
}
.perc-list__digit {
  font-size: 3.4rem;
}
.perc-list--pink {
  font-size: 1.6rem;
  color: #ffffff;
  letter-spacing: 3px;
}
@media print, (min-width: 1024px) {
  .perc-list--pink {
    flex-wrap: nowrap;
  }
}
@media print, (min-width: 1024px) {
  .perc-list--pink .perc-list__item {
    width: auto;
    max-width: 35rem;
  }
}
.perc-list--pink .perc-list__item:not(:last-child) {
  margin-right: 3rem;
}
@media print, (min-width: 1280px) {
  .perc-list--pink .perc-list__item:not(:last-child) {
    margin-right: 12rem;
  }
}
.perc-list--pink .perc-list__digit {
  color: #FE6F61;
}
.construct {
  padding-top: 5rem;
  padding-bottom: 5rem;
  height: 50vh;
}
.green-bordered {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.6em;
  color: #8A8C95;
}
@media print, (min-width: 1024px) {
  .green-bordered {
    font-size: 1.7rem;
    margin-left: 4rem;
  }
}
.green-bordered:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1.8rem;
  height: 1px;
  background-color: #005944;
}
.centers__header {
  font-family: 'Druk', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  color: #005944;
  text-transform: uppercase;
  width: 20rem;
  margin-bottom: 2rem;
}
@media print, (min-width: 768px) {
  .centers__header {
    font-size: 2rem;
  }
}
@media print, (min-width: 1024px) {
  .centers__header {
    margin-bottom: 11.6rem;
  }
}
.centers__list {
  columns: 2;
  column-gap: 2rem;
}
@media print, (min-width: 1024px) {
  .centers__list {
    column-gap: 15rem;
  }
}
.centers__li {
  font-size: 1.4rem;
  font-weight: 700;
  padding-top: 5px;
  padding-bottom: 5px;
}
@media (max-width: 767px) {
  .content-menu {
    display: none;
  }
}
@media print, (min-width: 768px) {
  .content-menu {
    display: flex;
    font-family: 'Druk', sans-serif;
  }
}
.content-menu__link {
  font-size: 1.3rem;
  font-weight: 500;
  color: #1A1A1A;
  text-decoration: none;
}
.content-menu__link:not(:last-child) {
  margin-right: 3rem;
}
.content-menu__link--active {
  color: #FE6F61;
}
.content-menu--green {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}
.content-menu--green .content-menu__link {
  color: #005944;
}
.content-menu--green .content-menu__link--active {
  color: #1A1A1A;
}
.hwm {
  margin-top: 3rem;
  margin-bottom: 5rem;
}
@media print, (min-width: 1024px) {
  .hwm {
    display: flex;
    margin-top: 10.5rem;
    margin-bottom: 8rem;
  }
}
@media (max-width: 1023px) {
  .hwm__menu {
    margin-bottom: 1rem;
  }
}
@media print, (min-width: 1024px) {
  .hwm__menu {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
@media print, (min-width: 1024px) {
  .hwm__quote {
    flex-grow: 0;
    flex-shrink: 0;
    width: 35rem;
  }
  .hwm__quote--w52 {
    width: 52rem;
  }
}
.hwm .bread {
  padding-top: 0;
  padding-bottom: 0;
}
.hwm .content-menu {
  padding-top: 3.5rem;
}
@media (max-width: 1279px) {
  .hwm .content-menu {
    display: none;
  }
}
.btn-down {
  border: none;
  background-color: transparent;
}
.full-width-photo--about {
  margin-bottom: 6.5rem;
}
.full-width-photo img {
  display: block;
  width: 100%;
}
.qoute-text {
  font-size: 1.8rem;
  line-height: 1.7em;
  font-weight: 700;
  color: #FE6F61;
  text-transform: uppercase;
}
.logo-list {
  margin-bottom: 5.5rem;
}
@media print, (min-width: 1024px) {
  .logo-list {
    display: flex;
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
.logo-list__item {
  box-sizing: border-box;
  font-size: 1.4rem;
  line-height: 1.7em;
  color: #1a1a1a;
}
@media (max-width: 1023px) {
  .logo-list__item {
    margin-bottom: 3.5rem;
  }
}
@media print, (min-width: 1024px) {
  .logo-list__item {
    width: 25%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.logo-list__logo-wrp {
  margin-bottom: 1rem;
}
@media print, (min-width: 1024px) {
  .logo-list__logo-wrp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 8rem;
  }
}
.logo-list__logo {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.blog-preview-slider {
  margin-bottom: 5rem;
}
@media print, (min-width: 1024px) {
  .blog-preview-slider {
    margin-bottom: 10rem;
  }
}
.blog-preview-slider__inner {
  margin-bottom: 2rem;
}
@media print, (min-width: 1024px) {
  .blog-preview-slider__inner {
    margin-bottom: 6rem;
  }
}
.blog-preview-slider__slider-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-preview-slider__slider-arrows .slider-arrow:not(.slider-arrow--disabled) {
  cursor: pointer;
}
.blog-preview-slider__slider-arrows .slider-arrow:not(.slider-arrow--disabled):hover path {
  stroke: #FE6F61;
}
.blog-preview-slider__slider-arrows .slider-arrow:not(.slider-arrow--disabled):hover path.fill {
  fill: #FE6F61;
}
.blog-preview-slider__slider-arrows .slider-arrow:not(.slider-arrow--disabled)--next {
  margin-left: 3rem;
}
.blog-preview-slider__slider-arrows .slider-arrow:not(.slider-arrow--disabled)--disabled {
  opacity: 0.5;
}
@media print, (min-width: 768px) {
  .search-panel {
    display: flex;
    align-items: center;
  }
}
.search-panel--full-width {
  margin-bottom: 5.6rem;
}
@media (max-width: 767px) {
  .search-panel__input {
    width: 100%;
    margin-bottom: 2rem;
  }
}
@media print, (min-width: 768px) {
  .search-panel__input {
    width: 66rem;
    margin-right: 4rem;
  }
}
.search-panel__btn {
  box-sizing: border-box;
  min-width: 19rem;
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
}
.career-content__cities {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.4em;
  text-transform: uppercase;
  color: #005944;
  margin-bottom: 4rem;
}
@media print, (min-width: 768px) {
  .career-content__cities {
    font-size: 3rem;
  }
}
@media print, (min-width: 1280px) {
  .career-content__cities {
    margin-left: 70rem;
  }
}
.career-content__cities a {
  color: #005944;
}
.career-content__work {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.4em;
  color: #1A1A1A;
  text-transform: uppercase;
  margin-bottom: 5rem;
}
@media print, (min-width: 768px) {
  .career-content__work {
    font-size: 3rem;
    margin-bottom: 10rem;
  }
}
.career-content__work a {
  display: block;
  color: #FE6F61;
}
.career-content__perc {
  color: #1A1A1A;
  margin-bottom: 10rem;
}
@media (max-width: 767px) {
  .career-content__perc {
    font-size: 1.2rem;
    margin-bottom: 5rem;
  }
  .career-content__perc .perc-list__digit {
    font-size: 2.4rem;
  }
}
@media (max-width: 767px) and (max-width: 500px) {
  .career-content__perc .perc-list__item {
    width: auto;
  }
  .career-content__perc .perc-list__item:nth-child(odd) {
    width: 20rem;
  }
}
@media (max-width: 1023px) {
  .career-content__perc .perc-list__item {
    margin-right: 0 !important;
  }
}
.career-content__begin-header {
  font-family: 'Druk', sans-serif;
  font-size: 2rem;
  line-height: 1.4em;
  font-weight: 500;
  color: #1A1A1A;
  text-transform: uppercase;
  margin-bottom: 6rem;
}
@media print, (min-width: 768px) {
  .career-content__begin-header {
    font-size: 3.5rem;
  }
}
.career-content__challenge {
  font-family: 'Druk', sans-serif;
  font-size: 2rem;
  line-height: 1.4em;
  font-weight: 500;
  color: #005944;
  text-transform: uppercase;
  margin-bottom: 5rem;
}
@media print, (min-width: 768px) {
  .career-content__challenge {
    font-size: 3.5rem;
    margin-bottom: 10rem;
  }
}
.career-content__challenge a {
  color: #FE6F61;
}
.file-picker {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.file-picker__input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
.file-picker__btn {
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #FE6F61;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
}
.file-picker__name {
  font-size: 1.4rem;
  color: #8b8b8b;
}
.select-bold {
  position: relative;
  padding-right: 3.5rem;
}
.select-bold:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  border-right: 2px solid #1A1A1A;
  border-bottom: 2px solid #1A1A1A;
  transform: rotate(45deg) translate(-50%, -50%);
}
.select-bold__select {
  font-family: 'Druk', sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #1A1A1A;
  appearance: none;
  border: none;
  background-color: transparent;
}
.search-panel-with-city {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6.4rem;
}
.search-panel-with-city__select {
  width: 35rem;
}
.search-panel-with-city__select .picker__inner {
  border: none;
}
.search-panel-with-city__select .picker__header {
  font-family: 'Druk', sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #1A1A1A;
}
.search-panel-with-city__select .picker__header:after {
  right: 1rem;
  width: 1rem;
  height: 1rem;
  border-right: 2px solid #1A1A1A;
  border-bottom: 2px solid #1A1A1A;
}
.search-panel-with-city__select .picker__drop {
  padding: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.search-panel-with-city__select .picker__list-item .check__input:focus + .check__descr {
  color: #FE6F61;
}
.search-panel-with-city__select .picker__list-item .check {
  font-size: 2rem !important;
}
.search-panel-with-city__select .picker__list-item .check:hover {
  color: #FE6F61;
}
.search-panel-with-city__select .picker__search-input {
  border: none !important;
  font-size: 1.8rem !important;
}
.soc-list {
  display: flex;
  align-items: center;
}
.soc-list__item + .soc-list__item {
  margin-left: 2.5rem;
}
.h {
  background-color: #005944;
}
.h--main {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}
.h--main .h__container {
  background-color: transparent;
}
.h__container {
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 4.5rem;
  background-color: #005944;
}
@media print, (min-width: 1280px) {
  .h__container {
    padding-top: 8rem;
  }
}
.h__top {
  text-align: center;
  margin-bottom: 3rem;
}
@media print, (min-width: 1280px) {
  .h__top {
    position: absolute;
    top: 2.5rem;
    right: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
@media print, (min-width: 1280px) {
  .h__top .search-form {
    order: 1;
    margin-right: 4rem;
  }
}
@media (max-width: 1279px) {
  .h__top .lang-panel {
    justify-content: center;
    margin-bottom: 2rem;
  }
}
@media print, (min-width: 1280px) {
  .h__top .lang-panel {
    order: 2;
  }
}
@media print, (min-width: 1024px) {
  .h__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 1023px) {
  .h__part {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
  }
}
@media print, (min-width: 1024px) {
  .h__part {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }
}
.h__logo-wrp {
  display: inline-block;
  margin-bottom: 2rem;
}
.h__logo {
  display: block;
}
@media (max-width: 1023px) {
  .h__logo {
    width: 14rem;
  }
}
@media (max-width: 1279px) {
  .h__menu-wrp {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #005944;
    width: 100%;
    height: 100%;
    padding: 5rem 2rem;
    box-sizing: border-box;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s cubic-bezier(0, 0.9, 0.4, 1);
  }
}
@media (max-width: 1279px) {
  .h__menu {
    text-align: center;
  }
}
@media print, (min-width: 1280px) {
  .h__menu {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 1279px) {
  .h__menu--sub {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s;
  }
  .h__menu--sub .h__menu-item:first-child {
    padding-top: 2rem;
  }
  .h__menu--sub .h__menu-link {
    font-size: 1.4rem;
    font-weight: 400;
  }
}
@media print, (min-width: 1280px) {
  .h__menu--sub {
    display: none;
  }
}
.h__menu-item {
  font-weight: 700;
}
.h__menu-item + .h__menu-item {
  margin-top: 2rem;
}
@media print, (min-width: 1280px) {
  .h__menu-item + .h__menu-item {
    margin-top: 0;
    margin-left: 2rem;
  }
}
@media print, (min-width: 1440px) {
  .h__menu-item + .h__menu-item {
    margin-left: 3.5rem;
  }
}
.h__menu-link {
  font-size: 1.8rem;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
}
@media print, (min-width: 1280px) {
  .h__menu-link {
    font-size: 1.4rem;
  }
}
.h__request {
  width: 16rem;
}
@media (max-width: 1023px) {
  .h__request {
    grid-column: 1;
    grid-row: 2;
  }
}
@media print, (min-width: 1024px) {
  .h__request {
    margin-left: 4rem;
  }
}
@media print, (min-width: 1440px) {
  .h__request {
    width: 19.5rem;
  }
}
.h__recall {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  padding: 0;
  margin-left: auto;
}
@media (max-width: 1023px) {
  .h__recall {
    grid-column: 2;
    grid-row: 1;
  }
}
@media print, (min-width: 1024px) {
  .h__recall {
    width: 4rem;
    height: 4rem;
    margin-left: 1.7rem;
  }
}
.h__burger {
  z-index: 3;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 3rem;
}
@media (max-width: 1023px) {
  .h__burger {
    grid-column: 3;
    grid-row: 1;
  }
}
@media print, (min-width: 1024px) {
  .h__burger {
    margin-left: auto;
  }
}
@media print, (min-width: 1280px) {
  .h__burger {
    display: none;
  }
}
.h__soc-list {
  justify-content: center;
  padding-top: 5rem;
}
@media print, (min-width: 1280px) {
  .h__soc-list {
    display: none;
  }
}
.burger {
  height: 3rem;
  background-color: transparent;
  border: 0;
  outline: none;
  padding: 0;
}
@media print, (min-width: 1280px) {
  .burger {
    display: none;
  }
}
.burger:hover .burger__line,
.burger:active .burger__line {
  width: 4rem;
}
.burger__inner {
  position: relative;
  display: block;
  height: 0;
  width: 3.4rem;
}
.burger__line {
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  height: 0.2rem;
  background-color: #ffffff;
  transform: translateX(-50%);
  transition: all 0.2s ease;
}
.burger__line:first-child {
  width: 3.4rem;
  top: -1.3rem;
}
.burger__line:nth-child(2) {
  width: 3.4rem;
}
.burger__line:last-child {
  width: 3.4rem;
  top: 1.3rem;
}
@media (max-width: 1279px) {
  .h--opened .h__menu-wrp {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    transition-timing-function: cubic-bezier(0.26, 0.92, 0.34, 1);
    overflow: auto;
  }
}
.h--opened .burger__line:first-child {
  width: 3.4rem;
  top: 0;
  transform: translateX(-50%) rotate(45deg);
}
.h--opened .burger__line:nth-child(2) {
  width: 0;
}
.h--opened .burger__line:last-child {
  width: 3.4rem;
  top: 0;
  transform: translateX(-50%) rotate(-45deg);
}
.f {
  background-color: #005944;
}
@media print, (min-width: 1440px) {
  .f {
    overflow: hidden;
  }
}
.f__container {
  background-color: #005944;
  padding-bottom: 3rem;
  padding-top: 3rem;
}
@media print, (min-width: 1024px) {
  .f__container {
    padding-top: 10rem;
  }
}
@media (max-width: 1439px) {
  .f__container {
    overflow: hidden;
  }
}
.f__nav {
  margin-bottom: 7rem;
}
@media print, (min-width: 1024px) {
  .f__nav {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
@media (max-width: 1023px) {
  .f__nav-column {
    text-align: center;
  }
}
@media print, (min-width: 1024px) {
  .f__nav-column {
    width: 24rem;
  }
}
@media (max-width: 1023px) {
  .f__nav-list {
    margin-bottom: 1.5rem;
  }
}
.f__nav-list li:not(:last-child) {
  margin-bottom: 1em;
}
@media print, (min-width: 1024px) {
  .f__nav-list + .f__nav-list {
    margin-top: 3rem;
  }
}
@media (max-width: 1023px) {
  .f__nav-list ul {
    display: none;
  }
}
.f__nav-list-header {
  font-size: 1.7rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1.5rem;
}
.f__nav-list-link {
  font-size: 1.4rem;
  color: #ffffff;
  text-decoration: none;
}
.f__nav-list-link:not(:hover) {
  opacity: 0.47;
}
@media print, (min-width: 1024px) {
  .f__bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
.f__copywrong {
  font-size: 1.4rem;
  color: #ffffff;
}
@media (max-width: 1023px) {
  .f__copywrong {
    position: relative;
    z-index: 2;
    text-align: center;
    margin-bottom: 2rem;
  }
}
.f__soc-wrp {
  position: relative;
}
.f__soc-wrp:before {
  content: '';
  position: absolute;
  z-index: 0;
  top: -7rem;
  left: 50%;
  width: 36rem;
  height: 17rem;
  background: #FE6F61;
  border-radius: 29rem / 14rem;
}
@media (max-width: 1023px) {
  .f__soc-wrp:before {
    transform: translateX(-50%);
  }
}
@media print, (min-width: 1024px) {
  .f__soc-wrp:before {
    top: -5rem;
    left: -7rem;
  }
}
@media (max-width: 1023px) {
  .f__soc-header {
    display: none;
  }
}
@media print, (min-width: 1024px) {
  .f__soc-header {
    position: relative;
    z-index: 1;
    font-size: 1.4rem;
    color: #ffffff;
    margin-bottom: 1.8rem;
  }
}
.f__soc-list {
  position: relative;
  z-index: 1;
}
@media (max-width: 1023px) {
  .f__soc-list {
    justify-content: center;
  }
}
@media print, (min-width: 1024px) {
  .f__soc-list {
    padding-left: 1rem;
  }
}
.pink-grid-main {
  position: relative;
  margin-bottom: 4rem;
}
@media print, (min-width: 1280px) {
  .pink-grid-main {
    margin-bottom: 18rem;
  }
}
.pink-grid-main:before {
  content: '';
  position: absolute;
  z-index: 0;
  left: -6rem;
  top: -6rem;
  width: 27rem;
  height: 16rem;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('../img/idea-1-xs.svg');
}
@media print, (min-width: 1280px) {
  .pink-grid-main:before {
    left: -10rem;
    top: auto;
    bottom: 3.6rem;
    width: 866px;
    height: 818px;
    background-image: url('../img/idea-1.svg');
  }
}
.pink-grid-main__header {
  position: relative;
  margin-bottom: 3rem;
}
.pink-grid-main__list {
  margin-bottom: 2rem;
}
@media print, (min-width: 768px) {
  .pink-grid-main__list {
    margin-bottom: 4rem;
  }
}
.pink-grid-main__footer {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .pink-grid-main__footer {
    padding-left: 0 !important;
  }
}
@media (max-width: 767px) {
  .pink-grid-main__idea {
    display: none;
  }
}
@media print, (min-width: 768px) {
  .pink-grid-main__idea {
    position: relative;
    font-size: 1.7rem;
    line-height: 1.3em;
    color: #8A8C95;
    margin-left: 4rem;
  }
  .pink-grid-main__idea:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1.8rem;
    height: 1px;
    background-color: #005944;
  }
}
.picker {
  position: relative;
  font-size: 16px;
  padding-bottom: 30px;
}
@media print, (min-width: 1280px) {
  .picker {
    font-size: 14px;
  }
}
.picker__select {
  display: none;
}
.picker__inner {
  position: absolute;
  top: 0;
  left: 0;
  font-size: inherit;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  background-color: #fff;
}
.picker__header {
  position: relative;
  font-size: inherit;
  color: #333333;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 25px 5px 5px;
  background-color: #fff;
  border: 0;
  cursor: pointer;
}
.picker__header:focus {
  outline: 1px solid #FE6F61;
}
.picker__header:disabled {
  cursor: default;
  background-color: #f3f3f3;
}
.picker__header:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 9px;
  width: 8px;
  height: 8px;
  border-bottom: 1px solid #b9c0cf;
  border-right: 1px solid #b9c0cf;
  transform: rotate(45deg) translate(-50%, -50%);
}
.picker__header span {
  display: block;
  font-size: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.picker__drop {
  display: none;
}
.picker__list {
  position: relative;
  font-size: inherit;
  max-height: 250px;
  overflow: auto;
  padding: 5px 5px 0;
  box-sizing: border-box;
}
.picker__list--with-headers .picker__list-item {
  padding-left: 10px;
}
.picker__list-item {
  font-size: inherit;
  margin-bottom: 7px;
}
.picker__list-item--header,
.picker__list-item--header-link {
  font-weight: 700;
  padding-left: 0 !important;
}
.picker__list-item--header .check {
  cursor: default;
}
.picker__list-item .check {
  width: 100% !important;
  font-size: inherit !important;
  line-height: 1.3em;
}
.picker__search-wrp {
  position: relative;
}
.picker__search-wrp + .picker__list {
  padding-top: 0;
}
.picker__search-wrp + .picker__list .picker__list-item {
  padding-top: 5px;
}
.picker__search-input {
  width: 100%;
  font-size: inherit !important;
  background-color: transparent;
  border-left: none !important;
  border-right: none !important;
  box-sizing: border-box;
  padding: 6px 20px 6px 8px !important;
  border: 1px solid #d7d7d7;
}
.picker__search-clear-btn {
  position: absolute;
  top: 0;
  bottom: 1px;
  right: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 20px;
  padding: 0;
}
.picker__search-clear-btn:focus {
  outline: 1px solid #FE6F61;
}
.picker__search-clear-btn:before,
.picker__search-clear-btn:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(50% - 1px);
  left: 2px;
  height: 2px;
  width: 16px;
  background-color: #FE6F61;
}
.picker__search-clear-btn:before {
  transform: rotate(45deg);
}
.picker__search-clear-btn:after {
  transform: rotate(-45deg);
}
.picker .check__descr {
  font-size: inherit;
}
.picker--error .picker__inner {
  border: 1px solid #FE6F61;
}
.picker--opened {
  z-index: 2;
}
.picker--opened .picker__inner {
  border-color: #FE6F61;
}
.picker--opened .picker__drop {
  display: block;
}
.picker--opened .picker__header:focus {
  outline: none;
  color: #FE6F61;
}
.picker--single .check__descr {
  padding-left: 0 !important;
}
.picker--single .check__descr:before {
  display: none;
}
@media (max-width: 767px) {
  .gallery-block-wrp {
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (min-width: 768px) {
  .gallery-block-wrp {
    display: flex;
    padding-bottom: 20px;
  }
  .gallery-block-wrp .first-model-info {
    width: 280px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 30px;
  }
}
.popup-gallery {
  position: relative;
}
@media (min-width: 768px) {
  .popup-gallery {
    width: calc(100% - 310px);
    flex-shrink: 0;
    flex-grow: 0;
  }
}
.popup-gallery.full-width {
  width: 100%;
}
.popup-gallery.full-width .gallery-top {
  max-width: none;
}
.popup-gallery.full-width .gallery-top .swiper-slide img {
  max-height: none;
}
.popup-gallery .gallery-counter {
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  padding: 3px;
}
.popup-gallery .gallery-counter p {
  padding: 0;
  margin: 0;
  line-height: normal;
  color: #ffffff;
  font-size: 13px;
}
.popup-gallery .gallery-close-btn {
  display: none;
}
.popup-gallery .gallery-top {
  width: 100%;
  max-width: 585px;
}
@media (min-width: 768px) {
  .popup-gallery .gallery-top {
    margin-bottom: 20px;
  }
}
.popup-gallery .gallery-top .swiper-slide img {
  max-width: 100%;
  max-height: 225px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
@media (min-width: 768px) {
  .popup-gallery .gallery-top .swiper-slide img {
    max-height: 360px;
  }
}
@media (max-width: 767px) {
  .popup-gallery .gallery-top .swiper-button-white {
    background-size: 15px 44px;
  }
}
.popup-gallery .gallery-thumbs {
  height: 115px;
  width: 100%;
}
.popup-gallery .gallery-thumbs .swiper-container {
  height: 100%;
}
.popup-gallery .gallery-thumbs .swiper-slide {
  width: auto;
  box-sizing: border-box;
}
.popup-gallery .gallery-thumbs .swiper-slide img {
  max-height: 100%;
  display: block;
  cursor: pointer;
}
.popup-gallery:not(.active).ajax-loaded {
  display: none;
}
.popup-gallery:not(.active) .gallery-top .swiper-button-prev,
.popup-gallery:not(.active) .gallery-top .swiper-button-next {
  display: none;
}
.popup-gallery.active {
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.popup-gallery.active .gallery-counter {
  right: auto;
  left: 10px;
  top: 15px;
}
.popup-gallery.active .gallery-close-btn {
  display: block;
  position: absolute;
  z-index: 30;
  top: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.popup-gallery.active .gallery-close-btn span {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  height: 2px;
  background-color: #ffffff;
}
.popup-gallery.active .gallery-close-btn span:first-child {
  transform: rotate(45deg);
}
.popup-gallery.active .gallery-close-btn span:last-child {
  transform: rotate(-45deg);
}
.popup-gallery.active .gallery-top {
  height: 90%;
  width: 100%;
  max-width: none;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .popup-gallery.active .gallery-top {
    height: 100%;
  }
}
.popup-gallery.active .gallery-top .swiper-wrapper {
  height: 100% !important;
}
.popup-gallery.active .gallery-top .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.popup-gallery.active .gallery-top .swiper-slide img {
  max-height: 100%;
  display: block;
  cursor: pointer;
}
.popup-gallery.active .gallery-thumbs {
  background-color: #000000;
  height: 10%;
  box-sizing: border-box;
  padding: 1rem;
}
.popup-gallery.active .gallery-thumbs .swiper-button-next,
.popup-gallery.active .gallery-thumbs .swiper-button-prev {
  display: none;
}
.popup-gallery.active .gallery-thumbs .swiper-slide {
  border: solid 2px #000000;
}
.popup-gallery.active .gallery-thumbs .swiper-slide.swiper-slide-thumb-active {
  border-color: #005944;
}
.popup-gallery.active .gallery-thumbs .swiper-slide:hover:not(.swiper-slide-thumb-active) {
  border-color: #ffffff;
}
.popup-gallery--static-fixed:not(.active) .gallery-top {
  height: 225px;
}
@media print, (min-width: 768px) {
  .popup-gallery--static-fixed:not(.active) .gallery-top {
    height: 360px;
  }
}
.popup-gallery--static-fixed:not(.active) .gallery-top .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.cprt-warning {
  position: absolute;
  left: 20px;
  bottom: 10px;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}
[data-gallery-clickable] {
  cursor: pointer;
}
.mp {
  background-color: #005944;
  background-image: linear-gradient(270deg, rgba(0, 72, 55, 0) 35.76%, #005944 56.17%, #005944 70.25%, #004635 100%);
  margin-bottom: 2rem;
}
.mp__container {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: calc(100vh - 82px);
  min-height: 36rem;
  background-color: #005944;
  background-size: 47rem;
  background-position: 80% 100%;
  background-repeat: no-repeat;
  background-image: url('../img/main-poster.png');
}
@media print, (min-width: 768px) {
  .mp__container {
    min-height: 45rem;
    background-size: contain;
    background-position: 100% 100%;
    background-color: transparent;
  }
}
@media print, (min-width: 1280px) {
  .mp__container {
    min-height: 75rem;
  }
}
.mp__container-inner {
  width: 100%;
  padding-bottom: 21.5rem;
}
@media (max-width: 1439px) {
  .mp__container-inner {
    padding-bottom: 2.5rem;
  }
}
.mp__header {
  font-family: 'Druk', sans-serif;
  font-size: 2rem;
  line-height: 1.5em;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
@media print, (min-width: 768px) {
  .mp__header {
    font-size: 4.5rem;
    line-height: 1.2em;
    padding-top: 15rem;
    padding-bottom: 4rem;
  }
}
@media print, (min-width: 1024px) {
  .mp__header {
    padding-top: 40.5rem;
    padding-bottom: 8rem;
  }
}
@media print, (min-width: 1280px) {
  .mp__header {
    font-size: 6.5rem;
  }
}
.mp__link {
  font-family: 'Druk', sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  color: #FE6F61;
  text-decoration: none;
  border-bottom: 2px solid #FE6F61;
}
.mp__count {
  font-size: 1.7rem;
  line-height: 2em;
  color: #ffffff;
  letter-spacing: 0.1em;
}
@media print, (min-width: 1024px) {
  .mp__count {
    position: absolute;
    right: 10rem;
    bottom: 10rem;
    font-size: 2.6rem;
    line-height: 1.3em;
  }
}
@media print, (min-width: 1280px) {
  .mp__count {
    bottom: 17rem;
  }
}
.mp__count span {
  display: block;
  font-size: 4.5rem;
  font-weight: 700;
  color: #FE6F61;
  letter-spacing: 0;
}
.mp__list {
  display: none;
}
@media print, (min-width: 1024px) {
  .mp__list {
    position: absolute;
    bottom: 4.5rem;
    left: 11rem;
    right: 11rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.mp__list-link {
  display: block;
  width: 26rem;
  font-family: 'Druk', sans-serif;
  font-size: 1.5rem;
  line-height: 1.7em;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  border-bottom: 3px solid #FFFFFF;
}
.mp__footer {
  background-color: #ffffff;
  text-align: center;
  padding: 2rem;
}
@media print, (min-width: 768px) {
  .pb-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.7rem;
    margin-right: -1.7rem;
  }
}
@media print, (min-width: 1024px) {
  .pb-list {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
.pb-list--main {
  margin-bottom: 7rem;
}
@media print, (min-width: 768px) {
  .pb-list--main {
    margin-bottom: 15rem;
  }
}
@media print, (min-width: 768px) {
  .pb-list__item {
    width: 50%;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
    box-sizing: border-box;
  }
}
@media print, (min-width: 1024px) {
  .pb-list__item {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.pb-list__item .pb {
  width: 100%;
}
.pb {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 24rem;
  background-size: cover;
  background-position: 50% 50%;
  padding: 2.4rem 2rem;
  box-sizing: border-box;
  border-radius: 1.5rem;
}
@media (max-width: 767px) {
  .pb {
    margin-bottom: 3rem;
  }
}
@media print, (min-width: 768px) {
  .pb {
    height: 34rem;
    padding: 2.6rem 3rem;
    border-radius: 3rem;
  }
}
@media print, (min-width: 1024px) {
  .pb {
    height: 49.5rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
.pb__header {
  font-family: 'Druk', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.4em;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
@media print, (min-width: 768px) {
  .pb__header {
    font-size: 2rem;
    margin-bottom: 2.8rem;
  }
}
@media print, (min-width: 1024px) {
  .pb__header {
    font-size: 3rem;
  }
}
.pb__link {
  font-size: 1.4rem;
  letter-spacing: 0;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-transform: none;
}
@media print, (min-width: 768px) {
  .pb__link {
    font-size: 2rem;
    padding: 2rem 3rem;
  }
}
@media print, (min-width: 1024px) {
  .pb__link {
    padding: 2.5rem 5.2rem;
  }
}
.pink-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -1.7rem;
  margin-right: -1.7rem;
}
@media print, (min-width: 1024px) {
  .pink-grid {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
@media print, (min-width: 768px) {
  .pink-grid--mgb {
    margin-bottom: 10rem;
  }
}
.pink-grid__item {
  width: 100%;
  text-decoration: none;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
}
@media print, (min-width: 768px) {
  .pink-grid__item {
    width: 50%;
  }
}
@media print, (min-width: 1024px) {
  .pink-grid__item {
    width: 33.33%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.pgi {
  position: relative;
  perspective: 1000px;
}
.pgi:after {
  content: '';
  position: absolute;
  top: 1rem;
  right: 3.6rem;
  width: 3rem;
  height: 3rem;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('../img/plus.svg');
}
@media print, (min-width: 768px) {
  .pgi:after {
    top: 3.2rem;
    right: 5rem;
    width: 6.5rem;
    height: 6.5rem;
  }
}
.pgi__inner {
  position: relative;
  display: flex;
  align-items: flex-end;
  min-height: 10rem;
  padding: 1.3rem 1.4rem;
  border: 1px solid #005944;
  border-radius: 2rem;
  box-sizing: border-box;
  background-color: #fff;
}
@media print, (min-width: 768px) {
  .pgi__inner {
    height: 27rem;
    border-width: 3px;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    transition: all 0.2s ease-in;
  }
}
@media print, (min-width: 1280px) {
  .pgi__inner {
    padding: 2.3rem 4rem;
  }
}
.pgi__header {
  position: absolute;
  bottom: 1.3rem;
  left: 1.4rem;
  transform: translateY(0);
  font-size: 1.2rem;
  font-weight: 700;
  color: #005944;
  text-decoration: none;
  transition: all 0.2s ease-in;
}
@media print, (min-width: 768px) {
  .pgi__header {
    bottom: 2.3rem;
    font-size: 1.6rem;
  }
}
@media print, (min-width: 1280px) {
  .pgi__header {
    left: 4rem;
    font-size: 2.1rem;
  }
}
@media (max-width: 767px) {
  .pgi__list {
    display: none;
  }
}
@media print, (min-width: 768px) {
  .pgi__list {
    font-size: 1.4rem;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in 0.1s;
  }
  .pgi__list li + li {
    margin-top: 0.3em;
  }
}
@media print, (min-width: 1280px) {
  .pgi__list {
    font-size: 1.6rem;
  }
}
.pgi__descr {
  font-size: 1.6rem;
  font-weight: 700;
  color: #005944;
  transition: all 0.2s ease-in;
}
.pgi__link {
  position: relative;
  color: #005944;
  text-decoration: none;
}
.pgi__link:hover {
  border-bottom: 1px solid;
}
.pgi__link:before {
  content: '•';
  display: inline-block;
  vertical-align: top;
  margin-right: 0.3em;
}
@media print, (min-width: 768px) {
  .pgi:hover:after {
    display: none;
  }
  .pgi:hover .pgi__inner {
    background-color: #FE6F61;
    border-color: #FE6F61;
    transform: rotateY(20deg);
  }
  .pgi:hover .pgi__header {
    bottom: calc(100% - 2.3rem);
    transform: translateY(100%);
    color: #ffffff;
  }
  .pgi:hover .pgi__link {
    color: #ffffff;
  }
  .pgi:hover .pgi__list {
    opacity: 1;
    visibility: visible;
  }
}
.pink-grid-vertical {
  background-color: #F6F7F8;
  margin-bottom: 5rem;
}
@media print, (min-width: 768px) {
  .pink-grid-vertical {
    margin-bottom: 11rem;
  }
}
.pink-grid-vertical__container {
  background-color: #F6F7F8;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media print, (min-width: 1280px) {
  .pink-grid-vertical__container {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
.pink-grid-vertical .pgi:after {
  display: none;
}
@media print, (min-width: 1280px) {
  .pink-grid-vertical .pgi:hover .pgi__inner {
    transform: none;
  }
  .pink-grid-vertical .pgi:hover .pgi__header,
  .pink-grid-vertical .pgi:hover .pgi__descr {
    color: #ffffff;
  }
}
.pink-grid-vertical .pgi__inner {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: transparent;
}
.pink-grid-vertical .pgi__header {
  position: relative;
  bottom: auto !important;
  left: auto;
  transform: none !important;
}
.pink-grid-about {
  padding-top: 4rem;
  padding-bottom: 3rem;
  background-color: #F6F7F8;
  margin-bottom: 3rem;
}
@media print, (min-width: 768px) {
  .pink-grid-about {
    padding-top: 8rem;
    padding-bottom: 9rem;
    margin-bottom: 8rem;
  }
}
@media print, (min-width: 768px) {
  .pink-grid-about .pink-grid__item {
    width: 50%;
  }
}
@media print, (min-width: 1024px) {
  .pink-grid-about .pink-grid__item {
    width: 25%;
  }
}
.pink-grid-about .pgi__inner {
  min-height: 8rem;
  text-decoration: none;
}
@media print, (min-width: 1024px) {
  .pink-grid-about .pgi__inner {
    height: 22rem;
  }
}
.pink-grid-about .pgi__header {
  font-size: 1.6rem;
}
@media (max-width: 767px) {
  .pink-grid-about .pgi__header {
    padding-right: 4rem;
  }
}
.pink-grid-about .pgi__list {
  font-size: 1.4rem;
}
.industry {
  background-color: #F7F7F8;
}
.industry--platform {
  margin-top: 15rem;
  margin-bottom: 10rem;
}
.industry__container {
  position: relative;
  background-color: #F7F7F8;
  padding-top: 3.5rem;
  padding-bottom: 5rem;
}
@media print, (min-width: 768px) {
  .industry__container {
    padding-bottom: 11.5rem;
  }
}
.industry__container:before {
  content: '';
  position: absolute;
  z-index: 0;
  left: -5.5rem;
  top: -10rem;
  width: 30rem;
  height: 30rem;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('../img/idea-2-xs.svg');
}
@media print, (min-width: 768px) {
  .industry__container:before {
    left: -4rem;
  }
}
@media print, (min-width: 1280px) {
  .industry__container:before {
    left: 0.5rem;
    top: auto;
    bottom: 15.3rem;
    width: 883px;
    height: 1103px;
    background-image: url('../img/idea-2.svg');
  }
}
.industry__header {
  position: relative;
  z-index: 1;
  margin-bottom: 2.2rem;
}
.industry__list {
  position: relative;
  z-index: 1;
  display: flex;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-bottom: 3.5rem;
}
@media (max-width: 767px) {
  .industry__list {
    overflow-y: auto;
  }
}
@media print, (min-width: 768px) {
  .industry__list {
    flex-wrap: wrap;
  }
}
.industry__list-item-wrp {
  flex-grow: 0;
  flex-shrink: 0;
  width: 29rem;
  padding: 2rem 1.5rem;
  box-sizing: border-box;
}
@media print, (min-width: 768px) {
  .industry__list-item-wrp {
    width: 33.333%;
  }
}
@media print, (min-width: 1024px) {
  .industry__list-item-wrp {
    width: 25%;
  }
}
@media print, (min-width: 1280px) {
  .industry__list-item-wrp {
    width: 20%;
  }
}
.industry__list-item {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 25rem;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 2.2rem;
  text-decoration: none;
  padding: 3.3rem 3.7rem;
  box-sizing: border-box;
}
.industry__list-item:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 12.4rem;
  width: 12.4rem;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('../img/industry-plus.svg');
}
.industry__list-item:after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2.2rem;
  overflow: hidden;
}
.industry__li-header {
  position: relative;
  z-index: 2;
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
}
.industry .pink-grid-main__footer {
  position: relative;
  z-index: 1;
  padding-left: 12rem;
}
.industries {
  padding-top: 0.1rem;
  padding-bottom: 6rem;
  background-color: #F7F7F8;
  margin-bottom: 15rem;
}
.platform {
  background-color: #005944;
}
.platform--mgb {
  margin-bottom: 10rem;
}
.platform__container {
  background-color: #005944;
  padding-top: 3.5rem;
  padding-bottom: 11.5rem;
}
@media print, (min-width: 768px) {
  .platform__container {
    padding-top: 10.8rem;
    padding-bottom: 11.5rem;
  }
}
.platform__header {
  color: #ffffff;
  margin-bottom: 3rem;
}
@media print, (min-width: 768px) {
  .platform__header {
    text-align: center;
    margin-bottom: 8rem;
  }
}
.platform__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4.5rem;
}
@media print, (min-width: 768px) {
  .platform__list {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
.platform__li {
  text-decoration: none;
}
.platform__descr {
  font-size: 1.7rem;
  line-height: 1.7em;
  color: #ffffff;
  text-align: center;
  max-width: 66rem;
  margin-left: auto;
  margin-right: auto;
}
.pli {
  position: relative;
  padding: 1rem 1.5rem;
  box-sizing: border-box;
}
@media print, (min-width: 768px) {
  .pli {
    min-width: 15rem;
  }
}
.pli__header {
  position: relative;
  z-index: 1;
  font-size: 1.6rem;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  opacity: 0.3;
}
@media print, (min-width: 768px) {
  .pli__header {
    font-size: 2.7rem;
  }
}
.pli__header:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: transparent;
}
@media (max-width: 1279px) {
  .pli__descr {
    display: none;
  }
}
@media print, (min-width: 1280px) {
  .pli__descr {
    position: absolute;
    z-index: 0;
    left: -1.5rem;
    right: -1.5rem;
    top: -2rem;
    background-color: #004B39;
    border-radius: 2rem;
    padding: 8rem 3rem 3rem;
    font-size: 1.2rem;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
  }
}
.pli:hover {
  z-index: 2;
}
.pli:hover .pli__header {
  opacity: 1;
}
.pli:hover .pli__header:after {
  background-color: #ffffff;
}
.pli:hover .pli__descr {
  opacity: 1;
  visibility: visible;
}
.platform--platform {
  margin-bottom: 10rem;
}
@media print, (min-width: 1024px) {
  .platform--platform {
    margin-bottom: 15rem;
  }
}
@media (max-width: 1023px) {
  .platform--platform .platform__container {
    padding-top: 0;
    padding-bottom: 2rem;
  }
}
.platform--platform .platform__header-wrp {
  margin-bottom: 3rem;
}
@media print, (min-width: 1024px) {
  .platform--platform .platform__header-wrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10rem;
  }
}
.platform--platform .platform__header {
  text-align: left;
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .platform--platform .platform__header {
    margin-bottom: 2rem;
  }
}
.platform--platform .platform__descr {
  position: relative;
  text-align: left;
  margin-right: 0;
}
@media (max-width: 1023px) {
  .platform--platform .platform__descr {
    margin-left: 0;
  }
}
@media print, (min-width: 1024px) {
  .platform--platform .platform__descr {
    width: 58rem;
  }
}
.platform--platform .platform__descr:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #fff;
}
.platform--platform .platform__list {
  justify-content: left;
  padding-left: 0;
}
@media print, (min-width: 1024px) {
  .platform--platform .platform__list {
    padding-right: 20rem;
  }
}
.team {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 112.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  padding-bottom: 7rem;
  margin-top: -3rem;
}
@media (max-width: 1023px) {
  .team {
    flex-direction: column;
    align-items: center;
  }
}
@media print, (min-width: 1024px) {
  .team {
    padding-bottom: 17rem;
  }
}
@media (max-width: 1023px) {
  .team__text {
    order: 2;
  }
}
.team__header {
  font-size: 2rem;
  line-height: 1.7em;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
@media print, (min-width: 1024px) {
  .team__header {
    font-size: 3rem;
    margin-bottom: 5.4rem;
  }
}
.team__header span {
  display: block;
  color: #FE6F61;
}
.team__descr {
  max-width: 42.5rem;
  font-size: 1.7rem;
  line-height: 1.6em;
  color: #8A8C95;
  margin-bottom: 3rem;
}
@media print, (min-width: 1024px) {
  .team__descr {
    margin-bottom: 10rem;
  }
}
.team__photo {
  max-width: 54.3rem;
}
@media (max-width: 1023px) {
  .team__photo {
    order: 1;
    margin-bottom: 3rem;
  }
}
.team__photo img {
  display: block;
  max-width: 100%;
}
@media (max-width: 1023px) {
  .team__photo img + img {
    display: none;
  }
}
@media print, (min-width: 1024px) {
  .team__photo img + img {
    margin-top: 2.4rem;
  }
}
.map {
  position: relative;
  overflow: hidden;
}
.map--grey {
  background-color: #F7F7F8;
  margin-bottom: 5rem;
}
.map__map {
  display: block;
  width: 100%;
}
@media (max-width: 767px) {
  .map__map--pc {
    display: none;
  }
}
@media print, (min-width: 768px) {
  .map__map--mobile {
    display: none;
  }
}
@media (max-width: 1023px) {
  .map__text {
    padding-left: 1.7rem;
    padding-right: 1.7rem;
  }
}
@media print, (min-width: 1024px) {
  .map__text {
    position: absolute;
    z-index: 1;
    left: calc(50% + 10rem);
    bottom: 5rem;
  }
}
.map__header {
  max-width: 52rem;
  margin-bottom: 2rem;
}
.partners {
  padding-top: 8rem;
  padding-bottom: 5rem;
}
@media print, (min-width: 768px) {
  .partners {
    padding-bottom: 10rem;
  }
}
.partners__header {
  max-width: 81rem;
  font-size: 2.4rem;
  line-height: 1.6em;
  text-align: center;
  margin: 0 auto 5rem;
}
.partners__list {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .partners__list {
    overflow-x: auto;
    padding-bottom: 1rem;
  }
}
@media print, (min-width: 768px) {
  .partners__list {
    justify-content: center;
  }
}
.partners__li + .partners__li {
  margin-left: 5rem;
}
.partners__link img {
  filter: grayscale(100%);
  transition: 0.2s;
}
.partners__link:hover img {
  filter: grayscale(0%);
}
.rewards {
  background-color: #24503D;
}
@media print, (min-width: 1440px) {
  .rewards {
    overflow: hidden;
  }
}
.rewards__container {
  background-color: #24503D;
  background-image: url('../img/reward-bg.png');
  background-position: 80% 50%;
  background-repeat: no-repeat;
  padding-top: 6.7rem;
  padding-bottom: 6.7rem;
}
@media print, (min-width: 768px) {
  .rewards__container {
    background-position: 50% 50%;
  }
}
@media (max-width: 1439px) {
  .rewards__container {
    overflow: hidden;
  }
}
.rewards__top-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9.5rem;
}
.rewards__header {
  position: relative;
  color: #ffffff;
}
.rewards__header span {
  position: relative;
  z-index: 1;
}
.rewards__header:before {
  content: '';
  position: absolute;
  z-index: 0;
  top: -3rem;
  left: -4rem;
  width: 18rem;
  height: 9rem;
  background: #FE6F61;
  border-radius: 29rem / 14rem;
}
@media print, (min-width: 768px) {
  .rewards__header:before {
    top: -8rem;
    left: -9rem;
    width: 36rem;
    height: 17rem;
  }
}
.rewards__slider {
  max-width: 112.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 11rem;
}
.rewards__slide {
  text-align: center;
}
.rewards__slider-arrows {
  text-align: center;
}
@media print, (min-width: 1024px) {
  .rewards__slider-arrows {
    display: none;
  }
}
.feedback {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.feedback--vacancy {
  padding-top: 0;
}
.feedback__header {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
@media print, (min-width: 1024px) {
  .feedback__header {
    font-size: 4rem;
  }
}
.feedback__call {
  font-size: 1.6rem;
  margin-bottom: 4rem;
}
.feedback__call a {
  display: inline-block;
  font-size: 2.2rem;
  font-weight: 700;
  color: #FE6F61;
  text-decoration: none;
}
@media print, (min-width: 1024px) {
  .feedback__content {
    display: flex;
  }
}
.feedback__form-line {
  margin-bottom: 1.3rem;
}
@media print, (min-width: 1024px) {
  .feedback__form-line {
    display: flex;
    align-items: flex-start;
  }
}
.feedback__form-line .feedback__form-item {
  width: 100%;
  max-width: 42.5rem;
}
@media (max-width: 1023px) {
  .feedback__form-line .feedback__form-item + .feedback__form-item {
    margin-top: 1.3rem;
  }
}
@media print, (min-width: 1024px) {
  .feedback__form-line .feedback__form-item + .feedback__form-item {
    margin-left: 4rem;
  }
}
.feedback__file-line {
  margin-top: 1.3rem;
}
.feedback__agree-line {
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.feedback__submit {
  width: 23rem;
}
.feedback__inline-error {
  font-size: 1.4rem;
  color: #c00;
  margin-top: 0.5rem;
}
.feedback__inline-error.hidden {
  display: none;
}
.feedback__contacts {
  margin-left: 5rem;
  margin-top: 7rem;
}
@media print, (min-width: 1024px) {
  .feedback__contacts {
    margin-left: 15rem;
  }
}
.feedback__contacts-header {
  position: relative;
  font-family: 'Druk', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 3.4rem;
}
.feedback__contacts-header:before {
  content: '';
  position: absolute;
  top: 0;
  left: -6rem;
  width: 4.8rem;
  height: 4.8rem;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.0027 24.0004C47.0027 36.6929 36.2551 47.0008 23.7089 47.0008C11.1773 47.0008 1 36.7077 1 24.0004C1 11.308 11.1627 1 23.7089 1C36.2551 1 47.0027 11.308 47.0027 24.0004Z' stroke='%23FE6F61' stroke-miterlimit='10'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.1401 23.3779C12.9533 23.1948 12.9533 22.9201 13.1401 22.7675C13.3269 22.5844 13.6072 22.5844 13.7629 22.7675L17.7485 26.6741C18.8695 27.3456 20.3641 27.9865 22.1078 27.9865C23.7269 27.9865 25.6575 26.9794 27.5257 25.6364L38.2371 15.1373C38.424 14.9542 38.7042 14.9542 38.8599 15.1373C39.0467 15.3205 39.0467 15.5952 38.8599 15.7478L22.4192 31.8627C22.2323 32.0458 21.9521 32.0458 21.7964 31.8627L13.1401 23.3779Z' fill='%23FE6F61'/%3E%3C/svg%3E%0A");
}
.feedback__contacts-header span {
  display: block;
  font-size: 3rem;
  line-height: 1em;
  color: #FE6F61;
}
.feedback__link {
  font-size: 1.6rem;
  color: #005944;
  text-decoration: none;
  border-bottom: 1px solid;
}
.feedback__text-success {
  font-size: 2rem;
  color: #005944;
}
.feedback .input-line__input--textarea {
  height: 12.5rem;
}
.bread {
  padding-top: 4rem;
  padding-bottom: 3.5rem;
}
.bread__back-btn {
  display: inline-flex;
  align-items: center;
  font-family: 'Druk', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  color: #005944;
  text-decoration: none;
  margin-bottom: 3rem;
}
@media print, (min-width: 1280px) {
  .bread__back-btn {
    font-size: 2rem;
  }
}
.bread__back-btn:before {
  content: '';
  width: 4rem;
  height: 2rem;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='83' height='36' viewBox='0 0 83 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.1504 35.0273L0.546848 18.4238L17.2188 1.00029' stroke='%23005944' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M81.9229 18.3906L1.05873 18.3906' stroke='%23005944' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.2112 31.6797C14.1599 31.4235 3.00549 22.558 4.42329 19.842C6.25104 16.3743 31.276 18.612 31.4297 18.4071C30.3194 18.39 1.9635 18.5608 0.853175 18.5267L14.2112 31.6797Z' fill='%23005944'/%3E%3Cpath d='M31.4297 18.4063C31.2247 18.3209 6.90014 20.3026 4.64534 16.6642C3.07381 14.1361 11.5806 7.47416 12.0247 6.75672C11.4098 7.37167 1.51938 17.6208 0.90443 18.2357L10.5898 18.4066L31.4297 18.4063Z' fill='%23005944'/%3E%3C/svg%3E%0A");
  margin-right: 1rem;
}
@media print, (min-width: 1280px) {
  .bread__back-btn:before {
    width: 8.3rem;
    height: 3.6rem;
    margin-right: 2rem;
  }
}
.bread__list {
  font-family: 'Druk', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
}
@media print, (min-width: 1280px) {
  .bread__list {
    font-size: 4.3rem;
  }
}
.bread__li {
  display: inline;
}
.bread__li:not(:last-child):after {
  content: '/';
  margin-left: 0.3em;
}
.bread__link {
  color: #1a1a1a;
  text-decoration: none;
}
.bread__link--grey {
  color: #666666;
}
.bread__link--pink {
  color: #FE6F61;
}
.bread .hwm {
  margin-top: 0;
}
.description {
  background-color: #F6F7F8;
  margin-bottom: 2.3rem;
}
.description__container {
  background-color: #F6F7F8;
  padding-top: 5rem;
  padding-bottom: 4rem;
}
@media print, (min-width: 1024px) {
  .description__container {
    display: flex;
    align-items: flex-start;
    padding-top: 7.5rem;
    padding-bottom: 5rem;
  }
}
@media (max-width: 1279px) {
  .description__list {
    display: none;
  }
}
@media print, (min-width: 1280px) {
  .description__list {
    flex-grow: 0;
    flex-shrink: 0;
    width: 35rem;
    padding-right: 6rem;
    box-sizing: border-box;
  }
}
@media (max-width: 1023px) {
  .description__content-column {
    display: flex;
    flex-direction: column;
  }
}
@media print, (min-width: 1280px) {
  .description__content-column {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
@media (max-width: 1023px) {
  .description__content {
    display: contents;
  }
}
@media print, (min-width: 1024px) {
  .description__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 3.7rem;
  }
}
.description__content ul {
  margin-bottom: 3rem;
}
.description__content li {
  font-size: 1.4rem;
  margin-bottom: 0.3em;
}
.description__content li:before {
  content: '•';
  display: inline-block;
  vertical-align: top;
  margin-right: 0.3em;
}
@media (max-width: 1023px) {
  .description__content-main {
    order: 1;
    padding-bottom: 3rem;
  }
}
@media print, (min-width: 1024px) {
  .description__content-main {
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: 5rem;
  }
}
.description__header {
  font-size: 2rem;
  margin-bottom: 3rem;
}
@media print, (min-width: 1024px) {
  .description__header {
    font-size: 3.8rem;
  }
}
.description__lists {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}
@media print, (min-width: 1024px) {
  .description__lists {
    margin-bottom: 5rem;
  }
}
.description__say {
  font-family: 'Druk', sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.7em;
  color: #005944;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .description__content-sidebar {
    order: 3;
  }
}
@media print, (min-width: 1024px) {
  .description__content-sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    width: 30rem;
    padding-top: 5rem;
  }
}
.description__content-sidebar .header-text {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.description__list-content {
  margin-bottom: 3rem;
}
@media (max-width: 1023px) {
  .description__percents {
    order: 2;
    padding-bottom: 2rem;
  }
}
.connection {
  background-color: #F6F7F8;
  margin-bottom: 2.3rem;
}
.connection--mgb-lg {
  margin-bottom: 10rem;
}
.connection__container {
  background-color: #F6F7F8;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media print, (min-width: 768px) {
  .connection__container {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}
.connection__header {
  margin-bottom: 5rem;
}
.connection__list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
@media print, (min-width: 1280px) {
  .connection__list {
    justify-content: space-between;
  }
}
.connection__item {
  flex-grow: 0;
  flex-shrink: 0;
  width: 12rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  color: #1A1A1A;
}
@media (max-width: 1279px) {
  .connection__item {
    margin: 1rem;
  }
}
@media print, (min-width: 1280px) {
  .connection__item {
    font-size: 1.6rem;
  }
}
.connection__item:hover {
  color: #005944;
}
.connection__item:hover .connection__icon-wrp {
  background-color: #005944;
}
.connection__item:hover .connection__icon path {
  fill: #ffffff !important;
}
.connection__icon-wrp {
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.4rem;
}
.connection__icon {
  display: block;
  width: 60%;
}
@media (max-width: 767px) {
  .cases .swiper-wrapper {
    display: block;
  }
}
@media print, (min-width: 768px) {
  .cases {
    margin-bottom: 10rem;
  }
  .cases .swiper-wrapper {
    justify-content: space-between;
  }
}
.cases__header {
  margin-bottom: 2.3rem;
}
.cases__slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24rem;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 3rem;
  padding: 2.4rem 2rem;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .cases__slide {
    margin-bottom: 3rem;
  }
}
@media print, (min-width: 768px) {
  .cases__slide {
    height: 42rem;
    width: calc((100% - 4.4rem) / 2);
  }
}
@media print, (min-width: 1024px) {
  .cases__slide {
    padding: 5rem 6rem;
  }
}
.cases--career .cases__slide {
  justify-content: flex-end;
}
.cases__slide-header {
  font-family: 'Druk', sans-serif;
  font-size: 1.6rem;
  line-height: 1.4em;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 1rem;
}
@media print, (min-width: 1024px) {
  .cases__slide-header {
    font-size: 2.2rem;
  }
}
@media print, (min-width: 1280px) {
  .cases__slide-header {
    font-size: 3rem;
  }
}
.cases__slide-descr {
  font-size: 1.3rem;
  color: #ffffff;
}
@media print, (min-width: 768px) {
  .cases__slide-descr {
    letter-spacing: 0.15em;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.contacts {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 5rem;
}
@media print, (min-width: 1024px) {
  .contacts {
    padding-top: 10.3rem;
    padding-bottom: 10.5rem;
  }
}
@media print, (min-width: 1280px) {
  .contacts:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: -5rem;
    top: 0;
    width: 1142px;
    height: 521px;
    background-image: url("data:image/svg+xml,%3Csvg width='1142' height='521' viewBox='0 0 1142 521' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='193.617' cy='136.162' rx='189' ry='129.899' transform='rotate(1.59586 193.617 136.162)' stroke='%23FE6F61' stroke-width='2'/%3E%3Cpath d='M882.124 309.367L879.67 308.89C879.67 308.89 886.19 323.187 868.397 327.583C858.813 332.342 855.135 332.633 901.895 320.34C901.895 320.34 888.6 314.762 882.124 309.367Z' fill='%23005944'/%3E%3Cpath d='M372.539 84.7952L372.912 86.899C372.912 86.899 366.359 84.7952 371.859 94.2702C371.859 94.2702 371.359 94.7702 356.858 73.7701C356.858 73.7701 367.982 82.6332 372.539 84.7952Z' fill='%23FE6F61'/%3E%3Cpath d='M372.359 85.7705C606.859 108.771 774.358 158.771 886.358 316.77' stroke='url(%23paint0_linear)' stroke-width='2'/%3E%3Cpath d='M1140.5 417.5C1140.5 445.311 1120.77 470.781 1088.24 489.383C1055.75 507.962 1010.77 519.5 961 519.5C911.231 519.5 866.248 507.962 833.758 489.383C801.227 470.781 781.5 445.311 781.5 417.5C781.5 389.689 801.227 364.219 833.758 345.617C866.248 327.038 911.231 315.5 961 315.5C1010.77 315.5 1055.75 327.038 1088.24 345.617C1120.77 364.219 1140.5 389.689 1140.5 417.5Z' stroke='%23005944' stroke-width='3'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='400.878' y1='115.424' x2='678.378' y2='819.425' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0249475' stop-color='%23FE6F61'/%3E%3Cstop offset='0.453125' stop-color='%23005944'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: contain;
    background-position: 50% 50%;
  }
}
.contacts__content {
  position: relative;
}
.contacts__header-wrp {
  padding-bottom: 6rem;
}
@media print, (min-width: 768px) {
  .contacts__header-wrp {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
@media print, (min-width: 1024px) {
  .contacts__header-wrp {
    padding-bottom: 15rem;
  }
}
@media (max-width: 767px) {
  .contacts__header {
    margin-bottom: 2rem;
  }
}
@media print, (min-width: 768px) {
  .contacts__container {
    display: flex;
    align-items: flex-start;
  }
}
@media print, (min-width: 1024px) {
  .contacts__contacts {
    width: 75rem;
  }
}
.contacts__centers {
  padding-top: 3rem;
}
@media print, (min-width: 1024px) {
  .contacts__centers {
    padding-top: 9rem;
  }
}
.contacts__tel {
  font-family: 'Druk', sans-serif;
  padding-bottom: 3rem;
}
@media print, (min-width: 1024px) {
  .contacts__tel {
    padding-bottom: 10.6rem;
  }
}
.contacts__tel span {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0.5rem;
}
@media print, (min-width: 1024px) {
  .contacts__tel span {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
.contacts__tel a {
  font-size: 2rem;
  color: #FE6F61;
  text-decoration: none;
}
@media print, (min-width: 1024px) {
  .contacts__tel a {
    font-size: 4.5rem;
  }
}
.contacts__item {
  margin-bottom: 3rem;
}
@media print, (min-width: 768px) {
  .contacts__item {
    margin-bottom: 7.8rem;
  }
}
.contacts__item-header {
  font-family: 'Druk', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  color: #005944;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
@media print, (min-width: 768px) {
  .contacts__item-header {
    font-size: 2rem;
    margin-bottom: 2.5rem;
  }
}
.contacts__line {
  display: flex;
  font-size: 1.4rem;
  color: #000000;
}
.contacts__line-title {
  flex-grow: 0;
  flex-shrink: 0;
  width: 12rem;
  font-weight: 700;
}
.contacts__line-value {
  color: #000000;
}
.about-poster {
  background-image: url('../img/about-bg.jpg');
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
@media print, (min-width: 768px) {
  .about-poster {
    padding-top: 17rem;
    padding-bottom: 10rem;
  }
}
.about-poster__header {
  width: 20rem;
  font-size: 2.6rem;
  line-height: 1.1em;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 12rem;
}
@media print, (min-width: 768px) {
  .about-poster__header {
    width: 46rem;
    font-size: 6.6rem;
    margin-bottom: 8rem;
  }
}
@media (max-width: 1023px) {
  .about-poster__hidden-xs {
    display: none;
  }
}
.about-poster-footer {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}
@media print, (min-width: 768px) {
  .about-poster-footer {
    padding-top: 3rem;
    padding-bottom: 4.5rem;
  }
}
.photo-p {
  margin-bottom: 6rem;
}
@media print, (min-width: 1024px) {
  .photo-p {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.photo-p--text-center {
  align-items: center;
  margin-bottom: 10rem;
}
.photo-p--text-center .header-text {
  text-transform: uppercase;
}
@media print, (min-width: 1024px) {
  .photo-p--text-center .header-text {
    font-size: 3rem;
  }
}
.photo-p__content {
  max-width: 54rem;
}
.photo-p__header {
  font-size: 1.6rem;
  line-height: 1.4em;
  font-weight: 700;
  color: #005944;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.photo-p__header--pink {
  color: #FE6F61;
}
@media print, (min-width: 1024px) {
  .photo-p__header--big {
    font-size: 3rem;
  }
}
.photo-p__header--big-pink {
  color: #FE6F61;
  margin-bottom: 2rem;
}
@media print, (min-width: 1024px) {
  .photo-p__header--big-pink {
    font-size: 3rem;
  }
}
.photo-p__header--black {
  color: #1a1a1a;
}
.photo-p__header--black:not(.photo-p__header--big) {
  margin-top: 2rem;
}
.photo-p__header + .photo-p__header {
  margin-top: 5rem;
}
.photo-p__descr {
  font-size: 1.4rem;
  line-height: 1.7em;
  color: #1a1a1a;
}
@media print, (min-width: 1280px) {
  .photo-p__descr {
    width: 54rem;
  }
}
.photo-p__descr > *:not(:last-child) {
  margin-bottom: 1em;
}
.photo-p__descr + .photo-p__header {
  margin-top: 4.5rem;
}
.photo-p__photo {
  display: block;
  max-width: 100%;
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .photo-p__photo:not(.swiper-slide) {
    max-width: 50%;
  }
}
@media print, (min-width: 1024px) {
  .photo-p__photo--line {
    display: flex;
    margin-right: -25rem;
  }
}
.photo-p__photo--line img {
  display: block;
  max-width: 100%;
}
@media (max-width: 1023px) {
  .photo-p__photo--line img + img {
    margin-top: 2rem;
  }
}
@media print, (min-width: 1024px) {
  .photo-p__photo--line img + img {
    margin-left: 3.5rem;
  }
}
@media (max-width: 1023px) {
  .photo-p--photo-left .photo-p__photo {
    margin-bottom: 2rem;
  }
}
@media print, (min-width: 1024px) {
  .photo-p--photo-left .photo-p__photo {
    margin-right: 5rem;
  }
}
@media print, (min-width: 1280px) {
  .photo-p--photo-left .photo-p__photo {
    margin-right: 15rem;
  }
}
@media (max-width: 1023px) {
  .photo-p--photo-left .photo-p__slider {
    margin-bottom: 2rem;
  }
}
@media print, (min-width: 1024px) {
  .photo-p--photo-left .photo-p__slider {
    margin-right: 5rem;
  }
}
@media print, (min-width: 1280px) {
  .photo-p--photo-left .photo-p__slider {
    margin-right: 15rem;
  }
}
@media (max-width: 1023px) {
  .photo-p--photo-right .photo-p__descr {
    margin-bottom: 2rem;
  }
}
@media print, (min-width: 1024px) {
  .photo-p--photo-right .photo-p__photo {
    margin-left: 5rem;
  }
}
@media print, (min-width: 1280px) {
  .photo-p--photo-right .photo-p__photo {
    margin-left: 15rem;
  }
}
@media print, (min-width: 1024px) {
  .photo-p--photo-right .photo-p__slider {
    margin-left: 5rem;
  }
}
@media print, (min-width: 1280px) {
  .photo-p--photo-right .photo-p__slider {
    margin-left: 15rem;
  }
}
@media (max-width: 1023px) {
  .photo-p--text-first-xs {
    display: flex;
    flex-direction: column;
  }
  .photo-p--text-first-xs .photo-p__content {
    order: 1;
    margin-bottom: 2rem;
  }
  .photo-p--text-first-xs .photo-p__photo {
    order: 2;
    margin-bottom: 0;
  }
}
@media (max-width: 1023px) {
  .photo-p--combine-xs {
    display: flex;
    flex-direction: column;
  }
  .photo-p--combine-xs .photo-p__content {
    display: contents;
  }
  .photo-p--combine-xs .photo-p__part-top {
    order: 1;
  }
  .photo-p--combine-xs .photo-p__slider {
    order: 2;
    margin-bottom: 2rem;
  }
  .photo-p--combine-xs .photo-p__part-bottom {
    order: 3;
  }
}
.photo-p__slider .photo-p__photo {
  margin-left: 0;
  margin-right: 0;
}
.photo-p__slider .swiper-wrapper {
  margin-bottom: 3rem;
}
.photo-p__slider-arrows {
  text-align: center;
}
.mission-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
}
.mission-list__item {
  box-sizing: border-box;
  font-size: 1.4rem;
  line-height: 1.7em;
  color: #1a1a1a;
  margin-bottom: 2.8rem;
}
@media print, (min-width: 1024px) {
  .mission-list__item {
    display: flex;
    align-items: center;
    width: 50%;
    padding-right: 15rem;
    margin-bottom: 5.8rem;
  }
}
.mission-list__icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  width: 10rem;
  margin-right: 4rem;
}
@media (max-width: 1023px) {
  .mission-list__icon {
    margin-bottom: 1rem;
  }
}
.mission-list__icon img {
  display: block;
  max-width: 100%;
}
.mission-list__header {
  font-family: 'Druk', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.7rem;
}
.blog-preview {
  position: relative;
  height: auto;
  border: 1px solid #005944;
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
  box-sizing: border-box;
}
.blog-preview:hover .blog-preview__hover {
  opacity: 1;
  visibility: visible;
}
.blog-preview__img {
  display: block;
  height: 23rem;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.blog-preview__content {
  padding: 1.3rem 2rem 4rem;
}
.blog-preview__type {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.15em;
  color: #005944;
  text-transform: uppercase;
  margin-bottom: 1.7rem;
}
.blog-preview__header {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  min-height: 12rem;
  color: #1A1A1A;
}
.blog-preview__date {
  position: absolute;
  bottom: 1.5rem;
  left: 2.8rem;
  font-size: 1rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #757A7D;
}
.blog-preview__hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #005944;
  padding: 5.5rem 3rem 2rem;
  box-sizing: border-box;
  font-size: 1.4rem;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
}
.blog-preview__descr {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  margin-bottom: 1rem;
}
.blog-preview__bottom-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1rem;
}
.blog-preview__btype {
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 0;
}
.topics {
  padding-bottom: 16rem;
}
.topics__header {
  margin-bottom: 5rem;
}
@media (max-width: 1023px) {
  .topics__menu {
    display: none;
  }
}
@media print, (min-width: 1024px) {
  .topics__menu {
    margin-bottom: 10rem;
  }
}
@media print, (min-width: 768px) {
  .topics__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.topics__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 42rem;
  background-color: #eee;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 30px;
  padding: 4rem 2rem;
  box-sizing: border-box;
  color: #ffffff;
  text-decoration: none;
}
@media (max-width: 767px) {
  .topics__item:not(:last-child) {
    margin-bottom: 2rem;
  }
}
@media print, (min-width: 768px) {
  .topics__item {
    flex-grow: 0;
    flex-shrink: 0;
    width: calc((100% - 4rem) / 2);
    padding: 6rem 4rem;
  }
}
.topics__type {
  position: absolute;
  top: 4rem;
  left: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
}
.topics__date {
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.topics__item-header {
  font-family: 'Druk', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
@media print, (min-width: 768px) {
  .topics__item-header {
    font-size: 2.5rem;
  }
}
.topics__descr {
  font-size: 1.2rem;
  line-height: 1.7em;
  letter-spacing: 0.15em;
}
.blog-item {
  display: flex;
  color: #1A1A1A;
  padding-bottom: 5rem;
}
@media print, (min-width: 768px) {
  .blog-item {
    padding-top: 4rem;
    padding-bottom: 10rem;
  }
}
@media (max-width: 1023px) {
  .blog-item {
    flex-direction: column;
  }
}
@media print, (min-width: 1024px) {
  .blog-item {
    justify-content: space-between;
    padding-top: 8rem;
  }
}
@media (max-width: 1023px) {
  .blog-item__text {
    order: 2;
    margin-bottom: 2rem;
  }
}
@media print, (min-width: 1024px) {
  .blog-item__text {
    margin-right: 4rem;
  }
}
.blog-item__date {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}
.blog-item__header {
  font-size: 2rem;
  line-height: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
@media print, (min-width: 768px) {
  .blog-item__header {
    font-size: 3rem;
  }
}
.blog-item__descr {
  font-size: 1.6rem;
  line-height: 1.7em;
}
.blog-item__descr p:not(:last-child) {
  margin-bottom: 1em;
}
.blog-item__descr-part:not(:last-of-type) {
  margin-bottom: 5rem;
}
.blog-item__descr-header {
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .blog-item__photos {
    order: 1;
  }
  .blog-item__photos > img {
    display: none;
  }
}
@media print, (min-width: 1024px) {
  .blog-item__photos {
    flex-grow: 0;
    flex-shrink: 0;
    width: 30rem;
  }
}
@media print, (min-width: 1280px) {
  .blog-item__photos {
    width: 66rem;
  }
}
.blog-item__photos img {
  max-width: 100%;
}
.blog-item__photos-line {
  margin-bottom: 2rem;
}
@media (max-width: 1023px) {
  .blog-item__photos-line img:last-child {
    display: none;
  }
}
@media print, (min-width: 1280px) {
  .blog-item__photos-line {
    display: flex;
    margin-right: -25rem;
    margin-bottom: 4rem;
  }
}
.blog-item__photos-line img {
  display: block;
  max-width: 100%;
}
@media (max-width: 1279px) {
  .blog-item__photos-line img + img {
    margin-top: 2rem;
  }
}
@media print, (min-width: 1280px) {
  .blog-item__photos-line img + img {
    margin-left: 2rem;
  }
}
.career-slider {
  margin-bottom: 5rem;
}
@media print, (min-width: 1024px) {
  .career-slider {
    margin-bottom: 10rem;
  }
}
.career-slider .swiper-wrapper {
  margin-bottom: 3rem;
}
@media print, (min-width: 1024px) {
  .career-slider .swiper-wrapper {
    margin-bottom: 5rem;
  }
}
.career-slider__slide {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 45rem;
  background-color: #005944;
  border-radius: 3rem;
  overflow: hidden;
  font-size: 1.4rem;
  color: #ffffff;
  text-decoration: none;
  padding: 7rem 3rem;
  box-sizing: border-box;
  background-size: cover;
  background-position: 50% 50%;
}
.career-slider__slide:hover .career-slider__back {
  display: flex;
}
.career-slider__city {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1rem;
}
.career-slider__back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FE6F61;
  display: none;
  flex-direction: column;
  justify-content: center;
  padding: 5rem;
  box-sizing: border-box;
}
.career-slider__list li {
  margin-top: 0.8rem;
}
.career-slider__arrows {
  text-align: center;
}
.vacancy-slider {
  margin-bottom: 5rem;
}
@media print, (min-width: 1024px) {
  .vacancy-slider {
    margin-bottom: 10rem;
  }
}
.vacancy-slider .swiper-wrapper {
  margin-bottom: 3rem;
}
@media print, (min-width: 1024px) {
  .vacancy-slider .swiper-wrapper {
    margin-bottom: 5rem;
  }
}
.vacancy-slider__slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 28rem;
  border: 3px solid #005944;
  box-sizing: border-box;
  border-radius: 2rem;
  padding: 4rem;
  text-decoration: none;
}
.vacancy-slider__header {
  font-family: 'Druk', sans-serif;
  font-size: 1.6rem;
  line-height: 1.7em;
  font-weight: 500;
  color: #005944;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.vacancy-slider__descr-wrp {
  font-size: 1.4rem;
  line-height: 1.7em;
  color: #1A1A1A;
}
.vacancy-slider__descr {
  margin-bottom: 1rem;
}
.vacancy-slider__more {
  font-weight: 700;
}
.vacancy-slider__more span {
  text-decoration: underline;
}
.vacancy-slider__arrows {
  text-align: center;
}
.reviews {
  margin-bottom: 5rem;
}
@media print, (min-width: 1024px) {
  .reviews {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
  }
}
.reviews__item {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 35rem;
  background-color: #005944;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4rem;
  overflow: hidden;
  padding: 2.5rem 3rem 3rem;
  box-sizing: border-box;
}
@media print, (min-width: 768px) {
  .reviews__item {
    min-height: 45rem;
  }
}
@media (max-width: 1023px) {
  .reviews__item:not(:last-child) {
    margin-bottom: 3rem;
  }
}
@media print, (min-width: 1024px) {
  .reviews__item {
    width: calc((100% - 8rem) / 3);
  }
}
.reviews__quote {
  position: relative;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.15em;
  line-height: 1.7em;
  color: #ffffff;
  border: 2px solid #F5E080;
  border-radius: 1rem;
  padding: 1.2rem 2rem;
}
@media print, (min-width: 768px) {
  .reviews__quote {
    font-size: 1.4rem;
  }
}
.reviews__letter {
  position: absolute;
  left: 0;
  top: 100%;
  transform: translate(-50%, -50%);
  width: 4rem;
  font-size: 1.4rem;
  line-height: 4rem;
  color: #F5E080;
  text-align: center;
  background-color: #005944;
  border: 2px solid #F5E080;
  border-radius: 50%;
}
.reviews__header {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.7em;
  color: #ffffff;
}
.reviews__title {
  font-size: 1.4rem;
  color: #ffffff;
}
.reviews-pagination {
  display: flex;
  justify-content: center;
  bottom: auto;
}
.reviews-pagination__item {
  width: 8px;
  height: 8px;
  background-color: #C4C4C4;
  border-radius: 50%;
  margin: 0 5px;
}
.reviews-pagination__item--active {
  background-color: #005944;
}
